import React, { useRef } from 'react'
import { context } from './context'
import { useMap } from './useMap'

export const MapProvider = ({ config, context, children }) => {
  const ref = useRef()
  const { map } = useMap({
    ref,
    config,
  })

  const value = {
    map,
    ref,
  }

  return <context.Provider value={value}>{children}</context.Provider>
}

MapProvider.defaultProps = {
  context,
}

import React, { useEffect } from 'react'
import gsap from 'gsap'
import { heromap } from './style.module.scss'

const HeroMap = () => {
  useEffect(() => {
    gsap.fromTo(
      `.${heromap} circle`,
      {
        opacity: 0,
        x: -6,
      },
      {
        delay: 0.5,
        opacity: 1,
        x: 0,
        // duration: 1,
        // stagger: 0.1,
        stagger: {
          grid: 'auto',
          ease: 'power1.out',
          from: 'random',
          amount: 6,
        },
      }
    )
  })

  return (
    <svg
      className={heromap}
      // width="588"
      // height="588"
      viewBox="0 0 588 588"
      fill="#A6C3F3"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="6.15" cx="6.15063" cy="198.05" fill="#345FF6" />
      <circle r="6.15" cx="22.1428" cy="198.05" fill="#345FF6" />
      <circle r="6.15" cx="38.135" cy="198.05" />
      <circle r="6.15" cx="54.1252" cy="198.05" />
      <circle r="6.15" cx="70.1174" cy="198.05" />
      <circle r="6.15" cx="86.1096" cy="198.05" />
      <circle r="6.15" cx="22.1428" cy="182.058" />
      <circle r="6.15" cx="22.1428" cy="166.067" />
      <circle r="6.15" cx="38.135" cy="166.067" />
      <circle r="6.15" cx="38.135" cy="150.075" />
      <circle r="6.15" cx="54.1252" cy="150.075" />
      <circle r="6.15" cx="54.1252" cy="134.084" />
      <circle r="6.15" cx="70.1174" cy="134.084" />
      <circle r="6.15" cx="70.1174" cy="118.092" fill="#345FF6" />
      <circle r="6.15" cx="54.1252" cy="166.067" fill="#345FF6" />
      <circle r="6.15" cx="70.1174" cy="166.067" />
      <circle r="6.15" cx="38.135" cy="182.058" />
      <circle r="6.15" cx="54.1252" cy="182.058" />
      <circle r="6.15" cx="70.1174" cy="182.058" />
      <circle r="6.15" cx="86.1096" cy="182.058" />
      <circle r="6.15" cx="22.1428" cy="214.042" />
      <circle r="6.15" cx="38.135" cy="214.042" />
      <circle r="6.15" cx="54.1252" cy="214.042" />
      <circle r="6.15" cx="70.1174" cy="214.042" />
      <circle r="6.15" cx="86.1096" cy="214.042" />
      <circle r="6.15" cx="102.102" cy="214.042" />
      <circle r="6.15" cx="118.092" cy="214.042" />
      <circle r="6.15" cx="134.084" cy="214.042" />
      <circle r="6.15" cx="150.076" cy="214.042" />
      <circle r="6.15" cx="22.1428" cy="230.033" />
      <circle r="6.15" cx="38.135" cy="230.033" />
      <circle r="6.15" cx="54.1252" cy="230.033" />
      <circle r="6.15" cx="70.1174" cy="230.033" />
      <circle r="6.15" cx="86.1096" cy="230.033" />
      <circle r="6.15" cx="102.102" cy="230.033" />
      <circle r="6.15" cx="118.092" cy="230.033" />
      <circle r="6.15" cx="134.084" cy="230.033" />
      <circle r="6.15" cx="150.076" cy="230.033" />
      <circle r="6.15" cx="22.1428" cy="246.025" />
      <circle r="6.15" cx="38.135" cy="246.025" />
      <circle r="6.15" cx="54.1252" cy="246.025" />
      <circle r="6.15" cx="70.1174" cy="246.025" fill="#345FF6" />
      <circle r="6.15" cx="86.1096" cy="246.025" />
      <circle r="6.15" cx="102.102" cy="246.025" />
      <circle r="6.15" cx="118.092" cy="246.025" />
      <circle r="6.15" cx="134.084" cy="246.025" />
      <circle r="6.15" cx="150.076" cy="246.025" fill="#345FF6" />
      <circle r="6.15" cx="166.069" cy="246.025" />
      <circle r="6.15" cx="54.1252" cy="262.017" />
      <circle r="6.15" cx="70.1174" cy="262.017" />
      <circle r="6.15" cx="86.1096" cy="262.017" />
      <circle r="6.15" cx="102.102" cy="262.017" />
      <circle r="6.15" cx="118.092" cy="262.017" />
      <circle r="6.15" cx="134.084" cy="262.017" />
      <circle r="6.15" cx="150.076" cy="262.017" fill="#C9DBFF" />
      <circle r="6.15" cx="166.069" cy="262.017" />
      <circle r="6.15" cx="182.059" cy="262.017" />
      <circle r="6.15" cx="54.1252" cy="278.009" fill="#345FF6" />
      <circle r="6.15" cx="70.1174" cy="278.009" />
      <circle r="6.15" cx="70.1174" cy="294" />
      <circle r="6.15" cx="70.1174" cy="309.991" />
      <circle r="6.15" cx="54.1252" cy="325.983" />
      <circle r="6.15" cx="54.1252" cy="341.975" />
      <circle r="6.15" cx="70.1174" cy="341.975" />
      <circle r="6.15" cx="70.1174" cy="357.967" />
      <circle r="6.15" cx="86.1096" cy="373.958" fill="#345FF6" />
      <circle r="6.15" cx="86.1096" cy="389.95" />
      <circle r="6.15" cx="102.102" cy="389.95" />
      <circle r="6.15" cx="86.1096" cy="405.942" />
      <circle r="6.15" cx="102.102" cy="405.942" />
      <circle r="6.15" cx="102.102" cy="421.933" fill="#345FF6" />
      <circle r="6.15" cx="118.092" cy="437.924" />
      <circle r="6.15" cx="102.102" cy="453.916" />
      <circle r="6.15" cx="86.1096" cy="453.916" />
      <circle r="6.15" cx="70.1174" cy="453.916" fill="#C9DBFF" />
      <circle r="6.15" cx="118.092" cy="469.908" />
      <circle r="6.15" cx="102.102" cy="469.908" />
      <circle r="6.15" cx="86.1096" cy="469.908" />
      <circle r="6.15" cx="118.092" cy="485.9" />
      <circle r="6.15" cx="150.076" cy="485.9" />
      <circle r="6.15" cx="102.102" cy="485.9" />
      <circle r="6.15" cx="86.1096" cy="485.9" />
      <circle r="6.15" cx="134.084" cy="501.891" />
      <circle r="6.15" cx="118.092" cy="501.891" />
      <circle r="6.15" cx="102.102" cy="501.891" />
      <circle r="6.15" cx="134.084" cy="517.883" />
      <circle r="6.15" cx="134.084" cy="533.875" />
      <circle r="6.15" cx="150.076" cy="549.866" />
      <circle r="6.15" cx="166.069" cy="549.866" fill="#345FF6" />
      <circle r="6.15" cx="182.059" cy="549.866" />
      <circle r="6.15" cx="198.051" cy="549.866" />
      <circle r="6.15" cx="198.051" cy="565.858" />
      <circle r="6.15" cx="214.043" cy="549.866" />
      <circle r="6.15" cx="214.043" cy="565.858" />
      <circle r="6.15" cx="230.031" cy="549.866" />
      <circle r="6.15" cx="230.031" cy="565.858" fill="#345FF6" />
      <circle r="6.15" cx="246.026" cy="565.858" />
      <circle r="6.15" cx="262.018" cy="565.858" fill="#C9DBFF" />
      <circle r="6.15" cx="293.998" cy="565.858" />
      <circle r="6.15" cx="325.983" cy="565.858" fill="#345FF6" />
      <circle r="6.15" cx="325.983" cy="517.883" />
      <circle r="6.15" cx="325.983" cy="501.891" fill="#C9DBFF" />
      <circle r="6.15" cx="341.975" cy="501.891" />
      <circle r="6.15" cx="405.942" cy="501.891" fill="#345FF6" />
      <circle r="6.15" cx="437.924" cy="501.891" />
      <circle r="6.15" cx="421.932" cy="469.908" />
      <circle r="6.15" cx="421.932" cy="453.916" fill="#C9DBFF" />
      <circle r="6.15" cx="437.924" cy="453.916" fill="#345FF6" />
      <circle r="6.15" cx="421.932" cy="437.924" />
      <circle r="6.15" cx="453.916" cy="501.891" />
      <circle r="6.15" cx="485.899" cy="501.891" />
      <circle r="6.15" cx="485.899" cy="485.9" fill="#345FF6" />
      <circle r="6.15" cx="501.891" cy="485.9" />
      <circle r="6.15" cx="485.899" cy="469.908" />
      <circle r="6.15" cx="501.891" cy="469.908" />
      <circle r="6.15" cx="469.908" cy="469.908" />
      <circle r="6.15" cx="501.891" cy="501.891" />
      <circle r="6.15" cx="501.891" cy="533.875" />
      <circle r="6.15" cx="517.883" cy="501.891" fill="#345FF6" />
      <circle r="6.15" cx="517.883" cy="517.883" />
      <circle r="6.15" cx="533.875" cy="517.883" />
      <circle r="6.15" cx="549.865" cy="517.883" />
      <circle r="6.15" cx="549.865" cy="533.875" fill="#345FF6" />
      <circle r="6.15" cx="565.858" cy="533.875" />
      <circle r="6.15" cx="581.85" cy="533.875" fill="#C9DBFF" />
      <circle r="6.15" cx="581.85" cy="549.866" />
      <circle r="6.15" cx="565.858" cy="549.866" />
      <circle r="6.15" cx="549.865" cy="565.858" />
      <circle r="6.15" cx="581.85" cy="565.858" fill="#345FF6" />
      <circle r="6.15" cx="565.858" cy="517.883" />
      <circle r="6.15" cx="581.85" cy="517.883" />
      <circle r="6.15" cx="581.85" cy="501.891" fill="#345FF6" />
      <circle r="6.15" cx="565.858" cy="501.891" />
      <circle r="6.15" cx="549.865" cy="501.891" fill="#C9DBFF" />
      <circle r="6.15" cx="533.875" cy="501.891" fill="#C9DBFF" />
      <circle r="6.15" cx="533.875" cy="485.9" />
      <circle r="6.15" cx="549.865" cy="485.9" />
      <circle r="6.15" cx="565.858" cy="485.9" />
      <circle r="6.15" cx="341.975" cy="485.9" />
      <circle r="6.15" cx="357.965" cy="469.908" fill="#345FF6" />
      <circle r="6.15" cx="357.965" cy="453.916" fill="#345FF6" />
      <circle r="6.15" cx="341.975" cy="453.916" />
      <circle r="6.15" cx="373.957" cy="453.916" />
      <circle r="6.15" cx="357.965" cy="517.883" />
      <circle r="6.15" cx="357.965" cy="533.875" />
      <circle r="6.15" cx="309.992" cy="501.891" fill="#345FF6" />
      <circle r="6.15" cx="325.983" cy="485.9" />
      <circle r="6.15" cx="325.983" cy="469.908" />
      <circle r="6.15" cx="325.983" cy="453.916" />
      <circle r="6.15" cx="341.975" cy="437.924" />
      <circle r="6.15" cx="309.992" cy="485.9" />
      <circle r="6.15" cx="278.008" cy="485.9" />
      <circle r="6.15" cx="262.018" cy="485.9" />
      <circle r="6.15" cx="246.026" cy="485.9" fill="#345FF6" />
      <circle r="6.15" cx="230.031" cy="485.9" />
      <circle r="6.15" cx="214.043" cy="485.9" />
      <circle r="6.15" cx="198.051" cy="485.9" />
      <circle r="6.15" cx="262.018" cy="501.891" />
      <circle r="6.15" cx="262.018" cy="517.883" />
      <circle r="6.15" cx="246.026" cy="501.891" />
      <circle r="6.15" cx="230.031" cy="501.891" />
      <circle r="6.15" cx="214.043" cy="501.891" />
      <circle r="6.15" cx="278.008" cy="469.908" />
      <circle r="6.15" cx="262.018" cy="469.908" />
      <circle r="6.15" cx="246.026" cy="469.908" />
      <circle r="6.15" cx="230.031" cy="469.908" />
      <circle r="6.15" cx="214.043" cy="469.908" />
      <circle r="6.15" cx="198.051" cy="469.908" />
      <circle r="6.15" cx="278.008" cy="453.916" />
      <circle r="6.15" cx="262.018" cy="453.916" />
      <circle r="6.15" cx="246.026" cy="453.916" />
      <circle r="6.15" cx="230.031" cy="453.916" />
      <circle r="6.15" cx="214.043" cy="453.916" fill="#C9DBFF" />
      <circle r="6.15" cx="198.051" cy="453.916" />
      <circle r="6.15" cx="182.059" cy="453.916" />
      <circle r="6.15" cx="182.059" cy="437.924" />
      <circle r="6.15" cx="278.008" cy="437.924" />
      <circle r="6.15" cx="262.018" cy="437.924" fill="#345FF6" />
      <circle r="6.15" cx="246.026" cy="437.924" />
      <circle r="6.15" cx="230.031" cy="437.924" />
      <circle r="6.15" cx="214.043" cy="437.924" />
      <circle r="6.15" cx="293.998" cy="437.924" />
      <circle r="6.15" cx="293.998" cy="453.916" />
      <circle r="6.15" cx="278.008" cy="421.933" fill="#345FF6" />
      <circle r="6.15" cx="262.018" cy="421.933" />
      <circle r="6.15" cx="246.026" cy="421.933" />
      <circle r="6.15" cx="230.031" cy="421.933" />
      <circle r="6.15" cx="293.998" cy="405.942" />
      <circle r="6.15" cx="278.008" cy="405.942" />
      <circle r="6.15" cx="262.018" cy="405.942" />
      <circle r="6.15" cx="246.026" cy="405.942" fill="#345FF6" />
      <circle r="6.15" cx="293.998" cy="389.95" />
      <circle r="6.15" cx="278.008" cy="389.95" fill="#345FF6" />
      <circle r="6.15" cx="278.008" cy="373.958" fill="#345FF6" />
      <circle r="6.15" cx="357.965" cy="357.967" />
      <circle r="6.15" cx="373.957" cy="357.967" fill="#345FF6" />
      <circle r="6.15" cx="389.949" cy="357.967" />
      <circle r="6.15" cx="389.949" cy="341.975" />
      <circle r="6.15" cx="389.949" cy="309.991" fill="#345FF6" />
      <circle r="6.15" cx="373.957" cy="325.983" />
      <circle r="6.15" cx="357.965" cy="309.991" />
      <circle r="6.15" cx="341.975" cy="325.983" />
      <circle r="6.15" cx="357.965" cy="278.009" />
      <circle r="6.15" cx="341.975" cy="278.009" fill="#C9DBFF" />
      <circle r="6.15" cx="341.975" cy="262.017" />
      <circle r="6.15" cx="325.983" cy="262.017" fill="#345FF6" />
      <circle r="6.15" cx="341.975" cy="246.025" fill="#345FF6" />
      <circle r="6.15" cx="341.975" cy="230.033" />
      <circle r="6.15" cx="389.949" cy="54.1257" />
      <circle r="6.15" cx="389.949" cy="70.1169" fill="#345FF6" />
      <circle r="6.15" cx="373.957" cy="54.1257" />
      <circle r="6.15" cx="373.957" cy="86.1086" fill="#345FF6" />
      <circle r="6.15" cx="405.942" cy="54.1257" />
      <circle r="6.15" cx="389.949" cy="38.134" fill="#345FF6" />
      <circle r="6.15" cx="405.942" cy="38.134" />
      <circle r="6.15" cx="389.949" cy="22.1423" />
      <circle r="6.15" cx="405.942" cy="22.1423" />
      <circle r="6.15" cx="421.932" cy="54.1257" fill="#345FF6" />
      <circle r="6.15" cx="421.932" cy="38.134" />
      <circle r="6.15" cx="421.932" cy="22.1423" />
      <circle r="6.15" cx="389.949" cy="6.15063" />
      <circle r="6.15" cx="405.942" cy="6.15063" />
      <circle r="6.15" cx="389.949" cy="373.958" />
      <circle r="6.15" cx="389.949" cy="389.95" fill="#345FF6" />
      <circle r="6.15" cx="373.957" cy="373.958" />
      <circle r="6.15" cx="405.942" cy="357.967" />
      <circle r="6.15" cx="405.942" cy="373.958" fill="#C9DBFF" />
      <circle r="6.15" cx="325.983" cy="533.875" />
      <circle r="6.15" cx="325.983" cy="581.849" />
      <circle r="6.15" cx="373.957" cy="581.849" />
      <circle r="6.15" cx="389.949" cy="581.849" />
      <circle r="6.15" cx="405.942" cy="565.858" />
      <circle r="6.15" cx="118.092" cy="517.883" fill="#345FF6" />
      <circle r="6.15" cx="70.1174" cy="437.924" fill="#345FF6" />
      <circle r="6.15" cx="54.1252" cy="437.924" />
      <circle r="6.15" cx="70.1174" cy="421.933" />
      <circle r="6.15" cx="54.1252" cy="421.933" />
      <circle r="6.15" cx="38.135" cy="421.933" fill="#345FF6" />
      <circle r="6.15" cx="54.1252" cy="405.942" />
      <circle r="6.15" cx="38.135" cy="405.942" fill="#345FF6" />
      <circle r="6.15" cx="38.135" cy="389.95" />
      <circle r="6.15" cx="22.1428" cy="389.95" fill="#345FF6" />
      <circle r="6.15" cx="86.1096" cy="437.924" />
      <circle r="6.15" cx="86.1096" cy="278.009" />
      <circle r="6.15" cx="102.102" cy="278.009" fill="#C9DBFF" />
      <circle r="6.15" cx="118.092" cy="278.009" />
      <circle r="6.15" cx="134.084" cy="278.009" />
      <circle r="6.15" cx="150.076" cy="278.009" />
      <circle r="6.15" cx="166.069" cy="278.009" />
      <circle r="6.15" cx="182.059" cy="278.009" />
      <circle r="6.15" cx="86.1096" cy="294" fill="#C9DBFF" />
      <circle r="6.15" cx="102.102" cy="294" />
      <circle r="6.15" cx="118.092" cy="294" />
      <circle r="6.15" cx="134.084" cy="294" />
      <circle r="6.15" cx="150.076" cy="294" />
      <circle r="6.15" cx="166.069" cy="294" />
      <circle r="6.15" cx="182.059" cy="294" />
      <circle r="6.15" cx="118.092" cy="309.991" />
      <circle r="6.15" cx="134.084" cy="309.991" />
      <circle r="6.15" cx="150.076" cy="309.991" />
      <circle r="6.15" cx="166.069" cy="309.991" />
      <circle r="6.15" cx="134.084" cy="325.983" />
      <circle r="6.15" cx="150.076" cy="325.983" />
      <circle r="6.15" cx="134.084" cy="341.975" />
      <circle r="6.15" cx="150.076" cy="341.975" />
      <circle r="6.15" cx="166.069" cy="325.983" />
      <circle r="6.15" cx="182.059" cy="309.991" />
      <circle r="6.15" cx="102.102" cy="198.05" />
      <circle r="6.15" cx="118.092" cy="198.05" />
      <circle r="6.15" cx="134.084" cy="198.05" fill="#345FF6" />
      <circle r="6.15" cx="150.076" cy="198.05" />
      <circle r="6.15" cx="166.069" cy="198.05" />
      <circle r="6.15" cx="166.069" cy="182.058" />
      <circle r="6.15" cx="150.076" cy="182.058" />
      <circle r="6.15" cx="134.084" cy="182.058" />
      <circle r="6.15" cx="118.092" cy="182.058" />
    </svg>
  )
}

export default HeroMap

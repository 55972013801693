import React, { forwardRef } from 'react'
import { Helmet } from 'react-helmet'
import * as s from './Map.module.scss'

export const Map = forwardRef((props, ref) => {
  return (
    <>
      <Helmet>
        <link href="https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css" rel="stylesheet" />
      </Helmet>
      <div ref={ref} className={s.root}></div>
    </>
  )
})

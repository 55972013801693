import React from 'react'
import { Link } from 'gatsby'
import { about, about_descr, about_btns, about_btn } from './style.module.scss'

export const About = () => {
  return (
    <section id="about" className={about}>
      <p className={about_descr}>
        LIWU Law Group is established by professional attorneys who have experience as judges, prosecutors, and partners
        at major law firms. LIWU focuses on providing efficient and satisfactory legal service.
      </p>
      <div className={about_btns}>
        <Link className={about_btn} to="/korean">
          Liwu Korean
        </Link>
        <Link className={about_btn} to="/asean">
          Liwu Asean
        </Link>
      </div>
    </section>
  )
}

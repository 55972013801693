import React from 'react'
import { getDisplayName } from '~utils/getDisplayName'
import { MapProvider } from './MapProvider'

export const withMap = (config) => (WrappedComponent) => {
  const Wrapper = (props) => {
    return (
      <MapProvider {...config}>
        <WrappedComponent {...props} />
      </MapProvider>
    )
  }

  Wrapper.displayName = `WithMap(${getDisplayName(WrappedComponent)})`

  return Wrapper
}

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useWindowSize } from '~hooks/useWindowSize'
import DATA from './constants'
import { partners, partners_inner, partners_list, partners_item } from './style.module.scss'

export const Partners = () => {
  const data = useStaticQuery(graphql`
    {
      allImageSharp {
        edges {
          node {
            resize {
              originalName
            }
            original {
              width
            }
            gatsbyImageData(layout: CONSTRAINED, quality: 100, placeholder: BLURRED)
          }
        }
      }
    }
  `)
  const [width] = useWindowSize()
  const isSmallSize = width < 640

  return (
    <section className={partners}>
      <div className={partners_inner}>
        <h2>Our Partners</h2>
        <ul className={partners_list}>
          {DATA.map((name) => {
            const node = data.allImageSharp.edges.find(
              ({
                node: {
                  resize: { originalName },
                },
              }) => originalName.includes(name.toLowerCase().replace(/\s/g, ''))
            )
            return (
              <li className={partners_item} key={name}>
                <div style={{ maxWidth: node.node.original.width / (isSmallSize ? 9 : 7) }}>
                  <GatsbyImage image={node.node.gatsbyImageData} alt={name} style={{ display: 'block' }} />
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

import { createContext, useContext } from 'react'

export const context = createContext({
  map: undefined,
})

export const MapProvider = context.Provider
export const MapConsumer = context.Consumer

export const useMapContext = () => useContext(context)

const DATA = [
  {
    location: 'Seoul',
    coords: [127.0237, 37.5621],
    zoom: 10,
  },
  {
    location: 'Hanoi',
    coords: [105.83460569035624, 21.027425634322036],
    zoom: 10,
  },
  {
    location: 'Yangon',
    coords: [96.17369346279027, 16.845195003386227],
    zoom: 11,
  },
  {
    location: 'Phnom Penh',
    coords: [104.92073342024497, 11.583534757145822],
    zoom: 11,
  },
  {
    location: 'Jakarta',
    coords: [106.84324869364352, -6.20210993158242],
    zoom: 11,
  },
]

export const MAPBOX_CONFIG = {
  config: {
    style: 'mapbox://styles/minimal-dev/ckqbestxs2jeh18nu5uigkcs5',
  },
}

export const DEFAULT_PLACE = 'Seoul'

export default DATA

import React from 'react'
import Icon from '~components/Icon'
import DATA from './constants'
import {
  services,
  services_descr,
  services_content,
  services_title,
  services_list,
  services_item,
} from './style.module.scss'

export const Services = () => {
  return (
    <section className={services}>
      <div className={services_content}>
        <h2 className={services_title}>Providing Systematic & Efficient Legal Services</h2>
        <p className={services_descr}>
          Liwu Law Group provides legal counseling in various areas with immense experience and knowledge.
        </p>
      </div>
      <ul className={services_list}>
        {DATA.map((item, i) => (
          <li key={item + i} className={services_item}>
            <Icon name={`icon${i + (1 % 9)}`} size="32" />
            {item}
          </li>
        ))}
      </ul>
    </section>
  )
}

import React from 'react'
import Button from 'react-bootstrap/Button'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import DATA from './constants'
import {
  team,
  team_inner,
  team_title,
  team_btn,
  team_list,
  team_pic,
  team_position,
  team_name,
} from './style.module.scss'

export const Team = () => {
  const data = useStaticQuery(graphql`
    {
      allImageSharp {
        edges {
          node {
            resize {
              originalName
            }
            gatsbyImageData(layout: CONSTRAINED, width: 262, height: 300, quality: 100, placeholder: BLURRED)
          }
        }
      }
    }
  `)
  return (
    <section className={team}>
      <div className={team_inner}>
        <h2 className={team_title}>Our Team</h2>
        <Button as={Link} variant="white" className={team_btn} to="/team">
          See more
        </Button>
        <ul className={team_list}>
          {DATA.map(({ name, position }) => {
            const node = data.allImageSharp.edges.find(
              ({
                node: {
                  resize: { originalName },
                },
              }) => originalName.includes(name.replace(/\s/g, ''))
            )
            return (
              <li key={name}>
                <div className={team_pic}>
                  <GatsbyImage image={node.node.gatsbyImageData} alt={name} style={{ display: 'block' }} />
                </div>
                <p className={team_position}>{position}</p>
                <p className={team_name}>{name}</p>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

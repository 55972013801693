import React from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'
import { news_item, news_title, news_descr, news_footnote, inline } from './style.module.scss'

export const NewsItem = ({ title, brief, date, slug, isInline }) => {
  return (
    <Link to={`/news/${slug}`} className={cn(news_item, { [inline]: isInline })}>
      <p className={news_title}>{title}</p>
      <p className={news_descr}>{brief}</p>
      <p className={news_footnote}>{date}</p>
    </Link>
  )
}

import * as React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import { Hero } from './components/Hero'
import { About } from './components/About'
import { Services } from './components/Services'
import { Team } from './components/Team'
import { News } from './components/News'
import { Partners } from './components/Partners'
import Offices from './components/Offices'

export const Home = () => {
  return (
    <Layout>
      <Container>
        <SEO />
        <Hero />
        <About />
        <Services />
        <Team />
        <News />
        <Partners />
        <Offices />
      </Container>
    </Layout>
  )
}

import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Button from 'react-bootstrap/Button'
import { NewsItem } from '~components/NewsItem'
import { news, news_heading, news_btn, news_wrap, news_list } from './style.module.scss'

export const News = () => {
  const data = useStaticQuery(graphql`
    {
      allNewsJson {
        edges {
          node {
            id
            date
            brief
            title
            slug
          }
        }
      }
    }
  `)

  return (
    <section className={news}>
      <h2 className={news_heading}>Newsletter</h2>
      <Button as={Link} variant="white" className={news_btn} to="/news">
        See more
      </Button>
      <div className={news_wrap}>
        <div className={news_list}>
          {data.allNewsJson.edges.map(({ node: { title, brief, date, slug, id } }) => (
            <NewsItem key={id} slug={slug} title={title} brief={brief} date={date} isInline />
          ))}
        </div>
      </div>
    </section>
  )
}

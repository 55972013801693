const DATA = [
  'Construction & Engineering',
  'Litigation & Arbitration',
  'PF & Corporate Structuring',
  'Intellectual Property',
  'Entertainment & Content',
  'M&A / Corporate Dispute',
  'Litigation & Arbitration',
  'PF & Corporate Structuring',
  'Construction & Engineering',
]

export default DATA

import React from 'react'
import { Tab, Nav } from 'react-bootstrap'
import DATA, { MAPBOX_CONFIG } from './constants'
import { useMapContext } from './Map/context'
import { Map } from './Map'
import { withMap } from './Map/withMap'
import { useMapController } from './useMapController'
import { offices, offices_wrap, offices_tabs, offices_link } from './style.module.scss'

export const Offices = () => {
  const { ref } = useMapContext()
  const { onSelectHandler, defaultPlace } = useMapController()

  return (
    <section className={offices}>
      <h2>Offices</h2>
      <Tab.Container defaultActiveKey={defaultPlace}>
        <div className={offices_wrap}>
          <Nav variant="pills" className={offices_tabs} onSelect={onSelectHandler}>
            {DATA.map(({ location }) => (
              <Nav.Item key={location}>
                <Nav.Link as="button" className={offices_link} eventKey={location}>
                  {location}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <Map ref={ref} />
      </Tab.Container>
    </section>
  )
}

export default withMap(MAPBOX_CONFIG)(Offices)

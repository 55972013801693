import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { Link } from 'gatsby'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import Icon from '~components/Icon'
import HeroMap from '../HeroMap'
import { hero, hero_title, hero_subtitle, hero_content, hero_map } from './style.module.scss'

export const Hero = () => {
  gsap.registerPlugin(ScrollToPlugin)

  const handleScroll = (e, link) => {
    e.preventDefault()
    gsap.to(window, { scrollTo: link, ease: 'power2' })
  }
  return (
    <section className={hero}>
      <div className={hero_content}>
        <h1 className={hero_title}>
          <span className={hero_subtitle}>LIWU,</span> where competence creates best results
        </h1>
        <ButtonGroup>
          <Button onClick={(e) => handleScroll(e, '#about')} as={Link} to="#about">
            Explore
            <Icon name="arrow" size="18" />
          </Button>
          <Button as={Link} to="/contact" variant="white">
            Contact Us
          </Button>
        </ButtonGroup>
      </div>
      <HeroMap />
    </section>
  )
}

import { useEffect, useMemo } from 'react'
import DATA, { DEFAULT_PLACE } from './constants'
import { useMapContext } from './Map/context'

export const useMapController = ({ data = DATA, defaultPlace = DEFAULT_PLACE } = {}) => {
  const { map } = useMapContext()

  const dataDict = useMemo(() => {
    return data.reduce((acc, item) => {
      acc[item.location] = item
      return acc
    }, {})
  }, [])

  const moveToCoords = (item) => {
    if (!map) {
      return
    }

    map.flyTo({
      center: item.coords,
      zoom: item.zoom,
      speed: 2,
    })
  }

  useEffect(() => {
    moveToCoords(dataDict[defaultPlace])
  }, [map])

  const onSelectHandler = (tab) => {
    const itemConfig = dataDict[tab]
    moveToCoords(itemConfig)
  }

  return {
    onSelectHandler,
    defaultPlace,
  }
}
